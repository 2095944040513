<template>
  <v-menu :close-on-content-click="false" offset-y>
    <template #activator="{ on, attrs }">
      <v-btn icon small class="ml-6 mr-1">
        <v-icon color="white" v-bind="attrs" v-on="on">mdi-account-circle</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-list>
        <v-list-item>
          <v-list-item-avatar>
            <v-icon>mdi-account-circle</v-icon>
          </v-list-item-avatar>
          <v-list-item-content class="d-block mr-4">
            <v-list-item-title>{{ user.name }}</v-list-item-title>
            <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn text @click="logout">Logout</v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script setup>
import { defineProps } from 'vue';

defineProps({ user: { type: Object, default: () => ({}) } });
const logout = () => {
  window.localStorage.clear();
  window.location.href = `${window.location.origin}/logout`;
};

</script>
