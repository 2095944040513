import Vue from 'vue';
import VueRouter from 'vue-router';
import Vuetify from 'vuetify';
import { Amplify } from 'aws-amplify';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';

Vue.config.productionTip = false;

Vue.use(VueRouter);
Vue.use(Vuetify);

const awsconfig = {
  aws_project_region: process.env.VUE_APP_AWS_PROJECT_REGION,
  aws_appsync_graphqlEndpoint: process.env.VUE_APP_AWS_APPSYNC_GRAPHQLENDPOINT,
  aws_appsync_region: process.env.VUE_APP_AWS_APPSYNC_REGION,
  aws_appsync_authenticationType: process.env.VUE_APP_AWS_APPSYNC_AUTHENTICATION_TYPE,
  aws_appsync_apiKey: process.env.VUE_APP_AWS_APPSYNC_API_KEY
};

Amplify.configure(awsconfig);

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app');
