<template>
  <v-card class="transport" style="width: 100%" flat tile>
    <v-card-actions>
      <h4 class="pl-4 subtitle-2">{{ label }}</h4>
      <v-spacer />
      <v-btn icon @click="showMore = !showMore">
        <v-icon>{{ showMore ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
      </v-btn>
    </v-card-actions>
    <v-divider />
    <v-expand-transition>
      <div v-show="showMore" class="grey lighten-4">
        <v-row class="pa-4 no-gutters">
          <v-col class="d-flex justify-center py-0 mb-2" cols="12">
            <v-date-picker
              :value="value"
              full-width
              color="red"
              :show-current="showCurrent"
              no-title
              locale="fr"
              @input="handleFilter"
            />
          </v-col>
        </v-row>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script setup>
import { ref, defineEmits, defineProps } from 'vue';

const props = defineProps({
  label: { type: String, default: 'Date' },
  show: { type: Boolean, default: false },
  value: { type: String, default: null },
  showCurrent: { type: Boolean, default: () => true }
});

const emit = defineEmits(['input', 'clearFacet']);

const showMore = ref(props.show);

const handleFilter = input => {
  if (input) {
    if (input === props.value) { // Remove date filter if active
      emit('clearFacet');
    } else {
      emit('input', input);
    }
  }
};

</script>
