<template>
  <v-menu
    v-if="selectedItem !== null"
    v-model="showMenu"
    absolute
    :position-x="x"
    :position-y="y"
    offset-y
  >
    <v-list>
      <v-list-item @click="$emit('openInNewTab', selectedItem)">
        <v-list-item-title><v-icon>mdi-open-in-new</v-icon> Ouvrir Dans un Nouvel Onglet</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script setup>
import { defineExpose, defineEmits, nextTick, ref } from 'vue';

defineEmits(['openInNewTab']);

const selectedItem = ref(null);
const showMenu = ref(false);
const x = ref(0);
const y = ref(0);

const show = (e, item) => {
  e.preventDefault();
  selectedItem.value = item;

  x.value = e.clientX;
  y.value = e.clientY;
  nextTick(() => {
    showMenu.value = true;
  });
};

defineExpose({ show });

</script>
