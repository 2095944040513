<template>
  <v-form ref="form" class="pa-4">
    <v-row>
      <v-col cols="4">
        <v-text-field v-model="newGroup.name" v-bind="groupFieldsProperties('groupName')" />
      </v-col>
      <v-col cols="4">
        <span><v-menu ref="menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
          <template #activator="{ on, attrs }">
            <v-text-field
              v-model="newGroup.departureDate"
              v-bind="{ ...attrs, ...groupFieldsProperties('departureDate') }"
              @blur="newGroup.departureDate = parseDate(newGroup.departureDate)"
              v-on="on"
            />
          </template>
          <v-date-picker ref="picker" v-model="newGroup.departureDate" :min="new Date().toISOString().slice(0, 10)" no-title scrollable />
        </v-menu></span>
      </v-col>
      <v-spacer />
      <!-- <v-col cols="3">
        <v-switch v-model="newGroup.online" v-bind="groupFieldsProperties('online')" />
      </v-col> -->
    </v-row>
    <v-row>
      <v-col cols="8">
        <v-autocomplete
          v-model="newGroup.destination"
          :items="destinationList"
          :loading="isLoading"
          :search-input.sync="search"
          hide-no-data
          hide-selected
          v-bind="groupFieldsProperties('destination')"
          cache-items
        />
      </v-col>
    </v-row>
  </v-form>
</template>

<script setup>
import { defineProps, ref, watch, defineEmits, defineExpose, onMounted, computed } from 'vue';
import { debounce } from 'debounce';
import store from '@/store';
import groupFieldsProperties from '@/utils/forms';
import { requests } from '@/mixins/index';

const props = defineProps({ groupData: { type: Object, default: () => ({ name: '', departureDate: '', destination: '' }) } });

const form = ref(null);

const emit = defineEmits(['update:groupData', 'loaded']);

const newGroup = ref(structuredClone(props.groupData));

const destinations = ref([]);
const isLoading = ref(false);
const search = ref('');

const destinationList = computed(() => destinations.value);

onMounted(async () => {
  store.state.loading = true;
  const response = await requests.getDestinations(store.state.activeBrand);
  destinations.value = response.data.items;
  store.state.loading = false;
  emit('loaded');
});

const getSearchDestinations = async newVal => {
  isLoading.value = true;
  const destinationsResponse = await requests.getDestinations(store.state.activeBrand, !newVal ? newVal : newVal.trim());
  destinations.value = destinationsResponse.data.items;
  isLoading.value = false;
};

const destinationsDebounce = debounce(getSearchDestinations, 300);

watch(search, async newVal => {
  destinationsDebounce(newVal);
});

watch(newGroup, newVal => {
  emit('update:groupData', newVal);
}, { deep: true });

const parseDate = date => {
  if (!date) return null;

  const [day, month, year] = date.split('/');
  return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
};

const formReset = () => {
  console.log(props.groupData);
  newGroup.value = structuredClone(props.groupData);
  form.value.resetValidation();
};

defineExpose({ formReset, form });
</script>

<style></style>
