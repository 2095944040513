<template>
  <v-app
    v-if="user"
    ref="mainApp"
    style="min-height: 100vh; background-color: #eee !important"
    class="grey lighten-3"
  >
    <v-app-bar flat app color="primary" dark class="mb-3">
      <router-link :to="{ name: 'Home' }">
        <v-btn link text class="mx-1">
          <v-img
            alt="UCPA"
            class="shrink"
            :src="require('@/assets/ucpa.svg')"
            height="65"
            width="100"
          />
        </v-btn>
      </router-link>
      <v-chip class="mr-3 black--text" color="white" small>ADMIN HELLO</v-chip>

      <v-spacer />
      <!-- <SearchBar />
      <v-spacer /> -->
      <v-autocomplete
        v-if="showDestinationFacets"
        :value="destination"
        class="d-none d-sm-flex mr-5"
        :items="destinationList"
        hide-details
        item-text="label"
        item-value="value"
        label="Centre ou destination"
        outlined
        dense
        clearable
        @input="onDestinationInput"
      >
        <template #item="{ item }">
          <div class="selectItem">
            <div class="selectLabel">
              {{ item.label }}
            </div>
            <div class="selectCount">
              {{ item.count }}
            </div>
          </div>
        </template>
      </v-autocomplete>
      <v-select
        v-if="showDestinationFacets"
        :value="context"
        style="max-width: 200px"
        class="d-none d-sm-flex mr-5 text-capitalize"
        :items="contextsList"
        hide-details
        item-text="label"
        item-value="value"
        label="Contexte"
        outlined
        dense
        clearable
        @input="onContextInput"
      >
        <template #item="{ item }">
          <div class="selectItem">
            <div class="selectLabel">
              {{ item.label }}
            </div>
            <div class="selectCount">
              {{ item.count }}
            </div>
          </div>
        </template>
      </v-select>
      <v-select
        :value="brand"
        style="max-width: 200px"
        class="d-none d-sm-flex"
        :items="permittedBrands"
        hide-details
        item-text="label"
        item-value="value"
        label="Espace de Travail"
        outlined
        dense
        @input="onBrandInput"
      />
      <!-- <router-link :to="{ name: 'Targeting'}" class="pl-4">
        <v-btn icon>
          <v-icon>mdi-bullseye-arrow</v-icon>
        </v-btn>
      </router-link> -->
      <router-link :to="{ name: 'ReportsDashboard'}" class="pl-4">
        <v-btn icon>
          <v-icon>mdi-bell</v-icon>
        </v-btn>
      </router-link>
      <UserInfo :user="user" />
    </v-app-bar>

    <v-main class="pb-2">
      <v-progress-linear v-if="loadingBar" indeterminate color="primary" />
      <div v-if="error">
        <v-card class="ma-6" color="error" rounded="lg" outlined elevation="4">
          <v-card-text class="white--text text-h6">
            <div>
              Une erreur s'est produite, actualisez la page et réessayez. Si l´erreur persiste, contactez l´équipe de développement.
            </div>
          </v-card-text>
        </v-card>
        <v-card v-if="enviroment === 'test'" class="ma-6" color="error" rounded="lg" outlined elevation="4">
          <v-card-text class="white--text text-h6">
            Dev info:
            <p class="pl-6">{{ error }}</p>
          </v-card-text>
        </v-card>
      </div>
      <div v-else-if="!permittedBrands.length">
        <v-card class="ma-6" color="primary" rounded="lg" outlined elevation="4">
          <v-card-text class="white--text text-h6">
            <div>
              Vous n'avez pas les autorisations pour les espaces de travail disponibles.
            </div>
          </v-card-text>
        </v-card>
      </div>
      <router-view v-else-if="loaded" />
      <Notification />
    </v-main>
    <v-footer id="footer-style" padless>
      <v-col class="text-center grey--text text--darken-2" cols="12">
        {{ new Date().getFullYear() }} - <strong>UCPA</strong>
      </v-col>
    </v-footer>
  </v-app>
</template>

<script setup>
import { ref, computed, onErrorCaptured, watch, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue2-helpers/vue-router';
import store from '@/store';
// import SearchBar from './components/search/SearchBar';
import UserInfo from '@/components/UserInfo';
import Notification from '@/components/Notification';
import { getUser } from '@/utils/auth';
import { requests } from '@/mixins/requests';

const { VUE_APP_SSO_PUBLIC_JWK_SET } = process.env;

const router = useRouter();
const route = useRoute();

const ignoreRoutes = ['Home', 'NewGroup', 'ReportsDashboard', 'Targeting'];

const brands = [
  {
    label: 'UCPA',
    value: 'crm',
    permission: platform => platform.includes('alpha_') || platform === 'speedboat'
  },
  {
    label: 'LA BALAGUERE',
    value: 'crm_labalaguere',
    permission: platform => platform === 'labalaguere'
  }
];

const platforms = computed(() => (store.state.userPlatforms));

const permittedBrands = computed(() => (platforms.value.includes('*') ? brands : brands.filter(brand => platforms.value.some(brand.permission))));

const brand = computed(() => store.getters.getActiveBrand || permittedBrands.value[0].value);
const destination = computed(() => store.getters.getActiveDestination);
const context = computed(() => store.getters.getActiveContext);

const showDestinationFacets = computed(() => brand.value === 'crm');

const brandQuery = computed(() => route.query.brand);

const contextFacets = computed(() => store.getters.getContextFacets);
const destinationFacets = computed(() => store.getters.getDestinationFacets);

const destinationList = computed(() => {
  if (destinationFacets.value) {
    return destinationFacets.value.result.buckets.map(b => ({ label: b.key.charAt(0).toUpperCase() + b.key.slice(1), value: b.key, count: b.doc_count }));
  }

  return [];
});

const contextsList = computed(() => {
  if (contextFacets.value) {
    return contextFacets.value.result.buckets.map(b => ({ label: b.key.charAt(0).toUpperCase() + b.key.slice(1), value: b.key, count: b.doc_count }));
  }

  return [];
});

const getFacets = async () => {
  const aggregates = [{ field: 'metadata.context', name: 'context', type: 'terms' }, { field: 'metadata.center_destination_name', name: 'destination', size: 1000, type: 'terms' }];

  const and = [{ field: 'metadata.workspace', condition: { eq: store.state.activeBrand } }];

  if (context.value) {
    and.push({ field: 'metadata.context', condition: { eq: context.value } });
  }

  if (!platforms.value.includes('*')) {
    and.push({ or: platforms.value.map(permission => ({ field: 'metadata.platform', condition: { eq: permission } })) });
  }

  const filter = and.length ? { and: and } : null;

  const response = await requests.getRoomsFacets(aggregates, filter).then(
    r => r
  ).catch(err => {
    store.state.loading = false;
    return err;
  });

  if (response.success) {
    return response.data.aggregateItems;
  }

  return null;
};

watch(brandQuery, () => {
  const newBrand = route.query.brand;
  const values = permittedBrands.value.map(b => b.value);
  if (values.includes(newBrand)) {
    store.commit('setActiveBrand', newBrand);
    router.replace({ query: null });
  }
});

const handleFacets = async facetChanged => {
  const filters = await getFacets();

  if (filters) {
    if (['brand', 'context'].includes(facetChanged)) {
      const destinationAux = filters.find(f => f.name === 'destination');

      if (destinationAux) {
        store.commit('setDestinationFacets', destinationAux);
      }
    }
    if (['brand'].includes(facetChanged)) {
      const contextsAux = filters.find(f => f.name === 'context');

      if (contextsAux) {
        store.commit('setContextFacets', contextsAux);
      }
    }
  }
};

const onBrandInput = async newVal => {
  store.commit('setActiveBrand', newVal);
  store.commit('setActiveDestination', null);
  store.commit('setActiveContext', null);
  await handleFacets('brand');
  if (ignoreRoutes.includes(route.name)) {
    window.location.reload();
  } else {
    router.push({ name: 'Home' });
  }
};

const onDestinationInput = newVal => {
  store.commit('setActiveDestination', newVal);
  if (!ignoreRoutes.includes(route.name)) {
    router.push({ name: 'Home' });
  }
};

const onContextInput = async newVal => {
  store.commit('setActiveContext', newVal);
  await handleFacets('context');
  if (!ignoreRoutes.includes(route.name)) {
    router.push({ name: 'Home' });
  }
};

onMounted(async () => {
  await handleFacets('brand');
});

const user = ref({ name: '' });

getUser(store.state.token, VUE_APP_SSO_PUBLIC_JWK_SET).then(data => {
  user.value = data;
});

const loadingBar = computed(() => store.state.loading);

const loaded = true;

const enviroment = ref(process.env.VUE_APP_ENVIROMENT);
const error = ref(null);
onErrorCaptured(e => {
  error.value = e;
});
</script>

<style lang="scss">
#customers-content {
  height: calc(100vh - 117px);
  overflow-y: auto;
}

.selectItem {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: nowrap;
  text-wrap: nowrap;
  overflow: hidden;
  gap: 2px;

}

.selectLabel {
  overflow: hidden;
  text-overflow: ellipsis;
}

.selectCount {
  background-color: rgba(199, 27, 0, 0.9);
  border-radius: 4px;
  text-align: center;
  line-height: 24px;
  font-size: 14px;
  font-weight:500;
  min-width: 24px;
  height: 24px;
  padding: 0px 3px 0px 3px;
  color: white;
}
</style>
