<template>
  <div id="search" class="search-holder">
    <v-text-field
      v-model="query"
      outlined
      dense
      hide-details
      clearable
      prepend-inner-icon="mdi-magnify"
      placeholder="Recherche"
      transition="scale-transition"
      @keyup.enter="search()"
      @click:clear="clearSearch()"
    />
  </div>
</template>

<script>

export default {
  name: 'SearchBar',
  components: { },
  data () {
    return { query: '' };
  },
  computed: {
    routeQuery () {
      return this.$route.query.query;
    }
  },
  watch: {},
  created () {

  },
  mounted () {
    this.$router.onReady(() => {
      if (this.routeQuery) {
        this.query = this.routeQuery;
      }
    });
  },
  methods: {
    clearSearch () {
      let routeName = 'Home';
      if (this.$route.fullPath.includes('signale')) {
        routeName = 'ReportsDashboard';
      }
      this.$router.push({ name: routeName }).catch(error => {
        if (error.name !== 'NavigationDuplicated') {
          throw error;
        }
      });
    },
    search (advanced = false) {
      const queryParams = {};

      // If is advanced search adds params
      if (advanced) {
        this.fields.forEach(field => {
          if (field.value && field.value !== '') {
            queryParams[field.field_name] = field.value;
          }
        });
        this.query = '';
      } else if (this.query !== '') {
        queryParams.query = this.query;
      }
      let routeName = 'Home';
      if (this.$route.fullPath.includes('signale')) {
        routeName = 'ReportsDashboard';
      }
      this.$router.push({ name: routeName, query: queryParams }).catch(error => {
        if (error.name !== 'NavigationDuplicated') {
          throw error;
        }
      });

      this.showMenu = false;
    }
  }
};
</script>

<style lang="scss">
#adv-search {
  position: relative;
}
.search-holder {
  position: relative;
  width: 100%;
  max-width: 600px;
}
.overlay {
  height: 100vh;
  width: 100vw;
  position: fixed;
  left: 0;
  top: 0;
  background-color: #00000080;
}
</style>
