const labels = {
  no: 'Non',
  yes: 'Oui',
  cancel: 'Annuler',
  save: 'Enregistrer',
  delete: 'Effacer',
  /* Group Creation/Edit **************************************** */
  new_group: 'Nouveau Groupe',
  edit_group: 'Modifier Groupe',
  create_group: 'Créer un nouveau Groupe',
  departure_date: 'Departure date',
  destination: 'Destination',
  clean: 'Effacer',
  create: 'Créer',
  group_saved: 'Groupe créé',
  check_fields: 'Vérifier les champs',
  delete_group_question: 'Voulez-vous vraiment supprimer ce groupe?',
  /* Group Dashboard ******************************************** */
  group_state: 'Statut',
  group_center: 'Centre',
  group_product: 'Produit',
  group_name: 'Nom du groupe',
  group_members: 'Membres',
  group_link: 'Lien du groupe',
  group_context: 'Contexte',
  group_created_at: 'Créé à',
  group_messages: 'Messages',
  /* Group Info ************************************************* */
  group: 'Groupe',
  group_id: 'Identifiant du groupe',
  discussion: 'Discussion',
  group_information: 'Informations sur le groupe',
  creation_date: 'Date de création',
  participants_information: 'Informations sur les membres',
  group_url: 'URL du groupe',
  product_url: 'URL du produit',
  delete_group: 'Supprimer Group',
  group_delete_confirmation_text: 'Êtes-vous sûr de vouloir supprimer ce groupe ?',
  add_user: 'Ajouter un Client',
  add_collaborateur: 'Ajouter un collaborateur',
  add_users_title: 'Souhaitez-vous ajouter ces utilisateurs?',
  add_users_message: 'Les utilisateurs suivants peuvent être ajoutés:',
  cant_add_users_message: 'Les utilisateurs suivants ne peuvent pas être ajoutés:',
  ban_user: 'Bannir Participant',
  user_ban_confirmation_text: 'Êtes-vous sûr de vouloir bannir ce participant?',
  block_user: 'Bloquer Participant',
  user_block_confirmation_text: 'Vous êtes sur le point de bloquer l\'accès de ce membre à ce groupe. Voulez-vous continuer ?',
  unblock_user: 'Débloquer Participant',
  user_unblock_confirmation_text: 'Vous êtes sur le point de débloquer l\'accès de ce membre à ce groupe. Voulez-vous continuer ?',
  activate: 'Réactiver',
  deactivate: 'Désactiver',
  /* Reports **************************************************** */
  report: 'Un Signalement',
  report_origin: 'Origine de l\'alerte',
  reported_user: 'Qui a posté le thread',
  report_user: 'Qui a signalé',
  report_id: 'Id signalement',
  report_date: 'Date d\'alerte',
  report_type: 'Type',
  content: 'Contenu',
  /* Discussion ************************************************* */
  exchange_history: 'Historique des échanges'
};

const getLabel = key => (labels[key] ? labels[key] : key);

export default getLabel;
