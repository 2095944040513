<template>
  <div>
    <v-range-slider
      class="mt-12 mx-8"
      :label="label"
      :max="range.max"
      :min="range.min"
      :value="value"
      validate-on-blur
      @end="handleFilter"
    >
      <!-- <template #append>
      <v-text-field
        class="mt-0 pt-0"
        hide-details
        single-line
        type="number"
        style="width: 60px"
        @change="$set(range, 1, $event)"
      />
    </template> -->
      <template #thumb-label="thumb">
        {{ thumb.value }}
      </template>
      <v-range-slider />
    </v-range-slider>
  </div>
</template>

<script setup>
import { defineEmits, defineProps } from 'vue';

defineProps({
  label: { type: String, default: 'Date' },
  field: { type: String, default: 'radioBoxes' },
  value: { type: Array, default: () => [0, 0] },
  range: { type: Object, default: () => ({ min: 0, max: 100, initial: 0 }) }
});

const emit = defineEmits(['input', 'clearFacet', 'removeFilter']);

const handleFilter = value => {
  if (value !== null) emit('input', value);
  else emit('clearFacet');
};
</script>
