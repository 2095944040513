import moment from 'moment';

// Global event bus
import Vue from 'vue';

export * from './requests';

export const EventBus = new Vue();

export const utils = {
  methods: {
    homepage () {
      this.clearCacheKeepAlive();
      this.$router.push({ name: 'Home' }).catch(error => {
        if (error.name !== 'NavigationDuplicated') {
          throw error;
        }
      });
    },
    newCustomer () {
      this.clearCacheKeepAlive();
      this.$router.push({ name: 'CreateCustomer' }).catch(error => {
        if (error.name !== 'NavigationDuplicated') {
          throw error;
        }
      });
    },
    // Checks if the object is a valid JSON and clones it (Converting to JSON and back)
    cloneObject: function (obj) {
      if (obj !== undefined && obj !== null && obj.constructor === Object) {
        return JSON.parse(JSON.stringify(obj));
      }

      return null;
    },
    defaultContent: function (groupsConfig, data = {}) {
      groupsConfig.forEach(groupConfig => {
        groupConfig.fields.forEach(field => {
          const fieldFound = this.$store.state.fieldsConfig.find(f => f.field_name === field.name);
          if (!data.hasOwnProperty(fieldFound.field_name) || data[fieldFound.field_name] === null) {
            if (fieldFound.hasOwnProperty('defaultValue')) {
              data[fieldFound.field_name] = fieldFound.defaultValue;
            } else {
              data[fieldFound.field_name] = null;
            }
          }
        });
      });

      return data;
    },
    makeToast: function (message, type, options) {
      const time = 3000;
      const color = options && options.color ? options.color : null;
      const toastOptions = {
        x: 'left',
        y: 'bottom',
        icon: 'mdi-information',
        timeout: options && options.time ? options.time : time
      };
      if (color) {
        toastOptions.color = color;
      }

      this.$toast[type](message, toastOptions);
    },
    // --------------------
    formatDate: function (date, destinationFormat) {
      return moment(date).format(destinationFormat);
    },

    formatGender: function (gender) {
      const genders = { mr: 'Mr.', mme: 'Mme.', miss: 'Miss' };
      return genders[gender] ? genders[gender] : gender;
    },
    editCustomer (uuid) {
      this.clearCacheKeepAlive();
      this.$router.push({ name: 'Customer', params: { uuid: uuid } });
    },
    clearCacheKeepAlive () {
      this.$store.commit('setKeepAlive', false);
      setTimeout(() => {
        this.$store.commit('setKeepAlive', true);
      }, 0);
    }
  }
};

// NOT IMPLEMENTED
export const translation = {
  data () {
    return {
      translations: {
        en: {},
        fr: {
          FIRSTNAME: 'Prénom',
          LASTNAME: 'Nom',
          BIRTHDATE: 'Date de Naissance',
          GENDER: 'Genre',
          EMAIL: 'Email',
          GOTOHOMEPAGE: "Retour à la page d'accueil",
          SAVE: 'Enregistrer',
          REMOVE: 'Supprimer',
          CUSTOMER_NOTFOUND: 'Client non trouvé!',
          ATTENTION: 'Attention',
          CONFIRM: 'Confirmer',
          UNDO: 'Annuler',
          WARNING_CREATE: 'Voulez-vous enregistrer ces informations?',
          WARNING_DELETE: 'Êtes-vous sûr de vouloir supprimer ce client?',
          WARNING_SAVE: 'Voulez-vous enregistrer ces informations?',
          WARNING_CLEAN: 'Voulez-vous effacer la liste des clients sélectionnés?',
          WARNING_CONFIRM: 'Êtes-vous sûr de vouloir confirmer ces participants?',
          SUCCESS_CREATE: 'Client créé avec succès',
          SUCCESS_DELETE: 'Client Supprimé',
          SUCCESS_SAVE: 'Informations client mises à jour',
          SUCCESS_CLEAN: '',
          SUCCESS_CONFIRM: '',
          CUSTOMERS_LIST: 'Liste de Clients',
          NEW_CLIENT: 'Nouveau Client',
          ACTIONS: 'Actions',
          SEARCH: 'Recherche'
        }
      }
    };
  },
  methods: {
    getTranslation: function (key, language) {
      return this.translations[language] && this.translations[language][key] ? this.translations[language][key] : key;
    }
  }
};
