<template>
  <div v-if="facetsToShow.length" class="breadcrumbs align-center">
    <v-chip
      v-for="(facet, i) in facetsToShow"
      :key="i"
      label
      small
      class="ma-1 red white--text"
      dark
      close
      @click:close="removeFacet(facet.field, facet.value)"
    >
      <div>
        <span>{{ facet.label }}</span>
      </div>
    </v-chip>
  </div>
</template>

<script setup>
import dayjs from 'dayjs';
import { defineProps, defineEmits, computed } from 'vue';

const props = defineProps({ facets: { type: Array, default: () => [] }, filters: { type: Array, default: () => ([]) } });

const emit = defineEmits(['removeFilter']);

const formatDate = date => dayjs(date, 'YYYY-MM-DD').format('DD-MM-YYYY');

const valueText = (value, facet) => {
  if (facet.component === 'date') return formatDate(value);

  if (facet.items) {
    const item = facet.items.find(i => i.value === value);
    if (item && item.label) return item.label;
  }

  return value;
};

const getBreadcrumbLabel = (value, facet) => {
  let label = '';

  if (facet.breadcrumbTitle) label += `${facet.breadcrumbTitle}: `;

  if (facet.range) {
    label += `${valueText(value[0], facet)} - ${valueText(value[1], facet)}`;
  } else label += valueText(value, facet);

  return label;
};

const facetsToShow = computed(() => {
  const toShow = [];
  props.facets.forEach(facet => {
    const filter = props.filters.find(f => f.field === facet.field);
    if (filter) {
      if (facet.type === 'multiValue') {
        filter.value.forEach(value => toShow.push({ value, field: filter.field, label: getBreadcrumbLabel(value, facet) }));
      } else {
        toShow.push({ value: filter.value, field: filter.field, label: getBreadcrumbLabel(filter.value, facet) });
      }
    }
  });
  return toShow;
});

const removeFacet = (field, value) => {
  emit('removeFilter', field, value);
};

</script>
<style lang="scss">
.breadcrumbs {
  min-height: 40px;
}
.clear-facets {
  text-decoration: underline;
  cursor: pointer;
}
.inline {
  display: inline-block;
}
.pointer {
  cursor: pointer;
}
</style>
