const groupFields = require('../settings/groupFields.json');
const getLabel = require('./labels.js').default;

const rulesDefined = {
  noSpaces: value => {
    const pattern = /\s/g;
    return !pattern.test(value) || "Ne doit pas contenir d'espaces";
  },
  wordsWithOnlyLetters: value => {
    const pattern = /^[a-zA-Z+àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ ]*$/g;
    return pattern.test(value) || 'Ne doit contenir que des lettres';
  },
  frenchPhoneNumber: value => {
    const pattern = /^((\+)33|0|0033)[1-9](\d{2}){4}$/g;
    if (!value) {
      return true;
    }
    return pattern.test(value) || 'Le numéro doit être valide';
  },
  email: value => {
    const pattern = /^(?=[^\s@]{0,64}@)[^\s@]+@[^\s@]+\.[^\s@.]+$/;
    // in case the string is null no need to check because is not required
    if (!value) {
      return true;
    }
    return pattern.test(value) || 'Email Invalide';
  },
  date: value => {
    const pattern = /[0-9]{4}-[0-9]{2}-[0-9]{2}/i;
    if (!value) {
      return true;
    }
    return pattern.test(value) || 'Date Invalide';
  }
};

const fieldRules = fieldSettings => {
  const rules = [];

  // Check rules
  if (fieldSettings.required) {
    rules.push(v => {
      const isEmpty = typeof v === 'string' ? v.trim().length === 0 : false;
      return (!!v && !isEmpty) || `${fieldSettings.label} est requis`;
    });
  }

  if (fieldSettings.counter) {
    rules.push(v => !v || (v && v.length <= fieldSettings.counter) || `Le ${fieldSettings.label} doit comporter ${fieldSettings.counter} caractères ou moins`);
  }

  if (fieldSettings.rules) {
    // Custom rules
    fieldSettings.rules.forEach(ruleName => {
      if (rulesDefined[ruleName]) {
        rules.push(rulesDefined[ruleName]);
      }
    });
  }

  return rules;
};

const findLabel = label => {
  if (!label) {
    return null;
  }
  return getLabel(label);
};

// To fill groups form
const groupFieldsProperties = field => {
  const fieldSettings = groupFields[field];

  const rules = fieldRules(fieldSettings);

  if (fieldSettings.label) {
    fieldSettings.label = findLabel(fieldSettings.label);
  }

  return { ...fieldSettings, rules: rules };
};

export default groupFieldsProperties;
