<template>
  <div class="col-12" :class="{ 'd-flex ' : facetsHorizontal}">
    <v-col v-for="(facetsColumn, index) in facetsToShow" :key="index" justify="space-between" style="padding: 5px">
      <div class="facet-column">
        <Breadcrumbs :facets="facetsColumn" :filters="filters" @removeFilter="removeFilter" />
        <div v-for="facet in facetsColumn" :key="facet.field">
          <component
            :is="facetComponents[facet.component].component"
            :value="filters.find(f => facet.field === f.field)?.value"
            v-bind="facet"
            @input="facetInput(facet.field, $event)"
            @clearFacet="clearFacet(facet.field)"
            @removeFilter="removeFilter"
          />
        </div>
      </div>
    </v-col>
  </div>
</template>

<script setup>
import { ref, watch, computed, defineProps, defineEmits } from 'vue';
import CheckBoxes from './facets/CheckBoxes';
import RadioBoxes from './facets/RadioBoxes';
import Date from './facets/Date';
import Breadcrumbs from '../components/Breadcrumbs';
import { getFacetsConfiguration } from '../configs/helper';
import RangeSlider from './facets/RangeSlider.vue';

const facetComponents = ref({
  date: { component: Date, valuesTypes: ['value'] },
  checkBoxes: { component: CheckBoxes, valuesTypes: ['multiValue'] },
  radioBoxes: { component: RadioBoxes, valuesTypes: ['value'] },
  rangeSlider: { component: RangeSlider, valuesTypes: ['range'] }
});

const props = defineProps({
  facetsValues: { type: Object, default: () => ({}) },
  facetsHorizontal: { type: Boolean, default: () => false },
  columnHeight: {
    type: Number,
    default: 285
  },
  facetsConfig: { type: Object, default: () => { getFacetsConfiguration('groups'); } }
});

const emit = defineEmits(['filtersUpdate']);

const filters = ref([]);
const facetsToShow = computed(() => {
  const facets = [];

  Object.entries(props.facetsConfig).forEach(([key, properties]) => {
    if (!facets[properties.orderInColumns]) {
      facets[properties.orderInColumns] = [];
    }

    if (!facetComponents.value[properties.component]) {
      throw new Error(`Facet Component "${properties.component}" does not exist.`);
    }

    if (!facetComponents.value[properties.component].valuesTypes.includes(properties.type)) {
      throw new Error(`Facet Component "${properties.component}" does not support value type of "${properties.type}".`);
    }

    facets[properties.orderInColumns].push({
      field: key,
      label: properties.label,
      items: props.facetsValues?.[key]?.data,
      component: properties.component,
      orderInPanel: properties.orderInPanel,
      breadcrumbTitle: properties.breadcrumbTitle,
      ...properties
    });
  });

  return facets.map(facetsColumn => facetsColumn.sort((a, b) => a.orderInPanel - b.orderInPanel));
});

watch(filters, () => {
  emit('filtersUpdate', filters.value);
}, { deep: true });

const facetInput = (field, value) => {
  const idx = filters.value.findIndex(f => f.field === field);
  if (idx >= 0) {
    filters.value[idx].value = value;
  } else {
    filters.value.push({ field, value });
  }
};

const clearFacet = field => {
  const idx = filters.value.findIndex(f => f.field === field);
  filters.value.splice(idx, 1);
};

const removeFilter = (field, value) => {
  const filterIdx = filters.value.findIndex(f => f.field === field);
  if (filterIdx >= 0) {
    if (props.facetsConfig[field]?.type === 'multiValue') {
      const idx = filters.value[filterIdx].value.findIndex(v => v === value);
      filters.value[filterIdx].value.splice(idx, 1);
      if (!filters.value[filterIdx].value.length) {
        filters.value.splice(filterIdx, 1);
      }
    } else {
      filters.value.splice(filterIdx, 1);
    }
  }
};
</script>

<style lang="scss">
.facet-column {
  //height: 395px;
  min-width: 16vw;
  overflow-x: hidden;
  overflow-y: scroll;

  //Scroll bar for firefox
  scrollbar-color: #c71900 #EEEEEE;
  scrollbar-width: thin;
}

.v-expansion-panel:before {
  box-shadow: none;
}

.toggle {
  .v-messages {
    display: none;
  }
}

//* Them red checkboxes .. **/

.facet-item {
  position: relative;

  input[type="checkbox"] {
    line-height: 2.1ex;
  }

  input[type="radio"],
  input[type="checkbox"] {
    position: absolute;
    left: -999rem;
  }

  label::before {
    content: "";
    display: inline-block;
    vertical-align: -25%;
    height: 16px;
    width: 16px;
    border: 1px solid rgb(166, 166, 166);
    border-radius: 4px;
    box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.25);
    margin-right: 0.5em;
  }
}

.facet-item--selected {
  label::after {
    content: "";
    position: absolute;
    width: 10px;
    height: 5px;
    background: rgba(0, 0, 0, 0);
    top: 10px;
    left: 11px;
    border: 3px solid white;
    border-top: none;
    border-right: none;
    transform: rotate(-45deg);
  }

  label::before {
    content: "";
    border: 1px solid red;
    background-color: red;
  }
}

.facet-column::-webkit-scrollbar {
  width: 5px;
}

.facet-column::-webkit-scrollbar-track {
  border-radius: 100px;
  background: #EEEEEE;
}

.facet-column::-webkit-scrollbar-thumb {
  border-radius: 100px;
  background: #c71900;
}
</style>
