<template>
  <div>
    <v-card v-if="notFound" class="ma-6" color="warning" rounded="lg" outlined elevation="4">
      <v-card-text class="white--text text-h6 d-inline-flex justify-space-between">
        <div>
          Groupe introuvable!
        </div>
        <v-btn @click="goBack">Retourner</v-btn>
      </v-card-text>
    </v-card>
    <v-card v-else-if="changingWorkspace" class="ma-6" color="warning" rounded="lg" outlined elevation="4">
      <v-card-text class="white--text text-h6 d-inline-flex justify-space-between">
        <div>
          Vous déménagez vers un autre workspace, souhaitez-vous continuer?
        </div>
        <div>
          <v-btn class="mr-4" @click="changeWorkspace">Continuer</v-btn>
          <v-btn @click="goBack">Retourner</v-btn>
        </div>
      </v-card-text>
    </v-card>
    <v-container v-else-if="group && !loading">
      <div class="d-flex align-center pb-3">
        <v-btn text icon color="black" @click="goBack">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <div class="text-h4 font-weight-medium ml-1">
          {{ group.name }}
        </div>
      </div>
      <v-card elevation="5" class="rounded-lg">
        <v-tabs v-model="tab" elevation="3" background-color="primary" dark height="64px" show-arrows>
          <v-tab v-for="item in tabItems" :key="item">
            {{ item }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <GroupInfo ref="groupInfo" :group="group" @updateGroup="g => group = g" />
          </v-tab-item>
          <v-tab-item>
            <v-card width="100%" height="70vh" class="d-flex">
              <iframe :src="`${helloUrl}`" class="grow"></iframe>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
      <v-dialog v-model="dialog" persistent width="60%">
        <v-card>
          <v-card-title class="text-h5">
            {{ getLabel('add_users_title') }}
          </v-card-title>
          <v-card-text>
            <div v-if="usersToAdd.length || collaboratorsToAdd.length">
              {{ getLabel('add_users_message') }}
              <div v-for="user in [...usersToAdd, ...collaboratorsToAdd]" :key="user.uuid">
                <v-icon color="green">mdi-check</v-icon> {{ `${user.firstname} ${user.lastname}` }}
              </div>
            </div>
            <div v-if="cantAddUsers.length">
              {{ getLabel('cant_add_users_message') }}
              <div v-for="{user, error} in cantAddUsers" :key="user.uuid">
                <v-icon color="red">mdi-close</v-icon> {{ `${user.firstname} ${user.lastname} : ${error}` }}
              </div>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="green darken-1"
              text
              :disabled="!usersToAdd.length && !collaboratorsToAdd.length"
              @click="addUsersToChat()"
            >
              {{ getLabel('yes') }}
            </v-btn>

            <v-btn
              color="red darken-1"
              text
              @click="cancelAddUsers"
            >
              {{ getLabel('no') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>
<!-- eslint-disable no-underscore-dangle -->
<script setup>
import { ref, defineProps, onMounted, computed } from 'vue';
import { useRouter, useRoute } from 'vue2-helpers/vue-router';
import getLabel from '@/utils/labels';
import GroupInfo from '@/components/GroupInfo.vue';
import store from '@/store';
import { requests } from '@/mixins/index';

const props = defineProps({ id: { type: String, default: 'id' } });

const router = useRouter();
const route = useRoute();

const groupInfo = ref(null);

const usersToAdd = ref([]);
const collaboratorsToAdd = ref([]);
const cantAddUsers = ref([]);
const tab = ref(null);
const tabItems = ref([getLabel('group'), getLabel('discussion')]);
const notFound = ref(false);
const changingWorkspace = ref(false);

const dialog = ref(false);

const group = ref(null);

const helloUrl = ref('');

const loading = computed(() => store.state.loading);
const changeWorkspace = () => {
  store.commit('setActiveBrand', group.value.workspace);
  changingWorkspace.value = false;
};

const goBack = () => {
  router.push({ name: 'Home' });
};

const cancelAddUsers = () => {
  dialog.value = false;
  router.replace({ query: null });
};

const checkParticipantsExists = async id => {
  const participantsAnd = [
    { field: 'roomParticipantsId', condition: { eq: group.value.id } },
    { field: 'metadata.trackId', condition: { eq: id } }
  ];
  const participantsResponse = await requests.getParticipants({ and: participantsAnd }).then(
    r => r
  ).catch(err => err);

  console.log('participantsResponse :', participantsResponse);

  if (participantsResponse.success) {
    if (participantsResponse.data.total > 0) return true;
    return false;
  }

  return false;
};

const getUsersToAdd = async () => {
  if (!(route.query.users)) {
    return;
  }

  const usersId = route.query.users.split(',');
  usersToAdd.value = [];

  const promises = usersId.map(async id => {
    const participantExists = await checkParticipantsExists(id);
    if (participantExists) return;
    usersToAdd.value.push(id);
  });

  await Promise.all(promises);

  if (usersToAdd.value.length === 0) {
    cancelAddUsers();
    return;
  }
  const response = await requests.getCustomers(usersToAdd.value.join(', '));

  usersToAdd.value = [];

  response.data.forEach(customer => {
    if (customer.error) {
      cantAddUsers.value.push({ user: { firstname: 'Client', lastname: 'introuvable' }, error: customer.error.uuid });
    } else if (customer.workspace !== group.value.workspace) {
      cantAddUsers.value.push({ user: customer, error: 'Inadéquation workspace' });
    } else if (!customer.sso_id || !customer.digital_account) {
      cantAddUsers.value.push({ user: customer, error: 'Pas de compte digital' });
    } else {
      usersToAdd.value.push(customer);
    }
  });

  dialog.value = true;
};

const getCollaboratorToAdd = async () => {
  if (!(route.query.collaborateur)) {
    return;
  }

  const collabId = route.query.collaborateur;

  const collabExists = await checkParticipantsExists(collabId);
  if (collabExists) return;

  const response = await requests.getCollaborator(collabId);
  const collab = response.data;

  if (response.error) {
    cantAddUsers.value.push({ user: { firstname: 'Collab', lastname: 'introuvable' }, error: collab.error.uuid });
  } else if (!collab.sso_id) {
    cantAddUsers.value.push({ user: collab, error: 'Pas de compte digital' });
  } else {
    collaboratorsToAdd.value.push(collab);
  }

  dialog.value = true;
};

const addUsersToChat = async () => {
  dialog.value = false;
  const newParticipants = [];
  const newUsers = [];

  if (collaboratorsToAdd) {
    const resp = await requests.postCollabParticipants(collaboratorsToAdd.value.map(u => u.uuid), group.value.id);
    if (resp.data) {
      newParticipants.push(...resp.data.participants);
      newUsers.push(...resp.data.users);
    }
  }
  if (usersToAdd.value.length) {
    const resp = await requests.postParticipants(usersToAdd.value.map(u => u.uuid), group.value.id);
    if (resp.data) {
      newParticipants.push(...resp.data.participants);
      newUsers.push(...resp.data.users);
    }
  }

  group.value.participants.push(...newParticipants);
  // users.value.push(...(newUsers.map(u => (normalizedUser(u, newParticipants.find(p => p.userParticipantsId === u.id))))));
  groupInfo.value.getUsers(true);
  router.replace({ query: null });
};

const prepareRoom = r => {
  const metadata = JSON.parse(r.metadata || '{}');
  return {
    active: r.active,
    name: r.name,
    createdAt: r.createdAt.split('T')[0],
    participants: r.participants.items || [],
    link: `${store.getters.getBrandUrl}/${r.id}`,
    id: r.id,
    destination: metadata.destination || metadata.center_destination_uuid || '',
    departureDate: metadata.departure_date || '',
    product: metadata.product_name || null,
    product_uuid: metadata.product_uuid || null,
    context: metadata.context,
    workspace: metadata.workspace,
    // eslint-disable-next-line no-underscore-dangle
    _version: r._version
  };
};

onMounted(async () => {
  if (!props.id) {
    router.push({ name: 'Home' });
    return;
  }

  store.state.loading = true;

  const response = await requests.getRoom(props.id).then(
    r => r
  );

  console.log('ROOM RESPONSE: ', response);

  if (!response.data) {
    notFound.value = true;
    return;
  }

  group.value = prepareRoom(response.data);

  await getUsersToAdd();
  await getCollaboratorToAdd();

  store.state.loading = false;

  helloUrl.value = `${store.getters.getBrandUrl}/admin/${props.id}?token=${store.state.token}`;

  if (group.value.workspace !== store.getters.getActiveBrand) {
    changingWorkspace.value = true;
  }
});

</script>
