<template>
  <!-- Snackbar for alert notifications -->
  <div>
    <v-snackbar
      v-for="(noti, index) in notifications"
      :key="index"
      v-model="noti.showing"
      bottom
      left
      :style="`bottom: ${(index) * 60}px`"
      v-bind="noti.settings"
    >
      {{ noti.message }}

      <div v-if="noti.link">
        <a
          :href="noti.link"
        >{{ noti.linkText }}
        </a>
      </div>

      <template #action="{ attrs }">
        <v-btn
          v-if="noti.hasButton"
          :color="noti.buttonColor"
          text
          v-bind="attrs"
          icon
          @click="closeNotification(index)"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>

export default {
  data: function () {
    return ({
      notifications: [],
      settings: {
        success: {
          hasButton: false,
          settings: {
            timeout: 3000,
            color: 'success'
          }
        },
        info: {
          hasButton: false,
          settings: {
            timeout: 3000,
            color: 'info'
          }
        },
        warning: {
          hasButton: false,
          settings: {
            timeout: 3000,
            color: 'warning',
            text: true,
            outlined: true
          }
        },
        danger: {
          showing: true,
          hasButton: true,
          buttonColor: 'white',
          settings: {
            color: 'error',
            timeout: -1
          }
        }
      }
    });
  },
  mounted () {
    // Listen for the show-notification event and its payload.
  },
  methods: {
    addNotification (notifications) {
      const notificationsToShow = [];
      notifications.map(notification => {
        const noti = { ...this.settings[notification.type] };
        noti.showing = true;
        noti.message = notification.message;
        noti.settings.timeout = notification.timeout ? notification.timeout : noti.settings.timeout;
        noti.link = notification.link;
        noti.linkText = notification.linkText;
        notificationsToShow.push(noti);
      });
      this.notifications = notificationsToShow;
    },
    closeNotification (index) {
      this.notifications.splice(index, 1);
    }
  }
};

</script>
