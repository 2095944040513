<template>
  <v-container v-show="loaded">
    <div class="d-flex align-center pb-3">
      <v-btn text icon color="black" @click="goBack">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <div class="text-h4 font-weight-medium ml-1">
        {{ getLabel('new_group') }}
      </div>
    </div>
    <v-card elevation="5" class="rounded-lg">
      <v-sheet elevation="3" class="rounded-lg rounded-b-0" color="primary">
        <v-card-title class="text-h6 white--text">{{ getLabel('create_group') }}</v-card-title>
      </v-sheet>
      <v-divider />
      <v-card-text>
        <v-alert v-if="errors.length" type="error" class="rounded-lg" dismissible @input="closeAlert">
          <div v-for="(error, index) in errors" :key="index">{{ error }}</div>
        </v-alert>
        <v-alert v-if="successes.length" type="success" class="rounded-lg" dismissible @input="closeAlert">
          <div v-for="(success, index) in successes" :key="index">{{ success }}</div>
        </v-alert>
      </v-card-text>
      <GroupForm ref="groupForm" :group-data="formNewGroup" @update:groupData="formUpdate" @loaded="formLoaded" />
      <v-divider />
      <v-card-text class="d-flex justify-end">
        <v-btn x-large color="warning" @click="resetForm()">
          <v-icon class="mr-3">mdi-broom</v-icon>
          {{ getLabel('clean') }}
        </v-btn>
        <v-btn x-large class="ml-4" color="success" @click="createGroup()">
          <v-icon class="mr-3">mdi-content-save</v-icon>
          {{ getLabel('create') }}
        </v-btn>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script setup>
import { useRouter } from 'vue2-helpers/vue-router';
import { ref } from 'vue';
import getLabel from '@/utils/labels';
import GroupForm from '@/components/GroupForm.vue';
import { requests } from '@/mixins/index';
import store from '../store';

const loaded = ref(false);

const formLoaded = () => {
  loaded.value = true;
};

const router = useRouter();

const formNewGroup = ref({ name: null, departureDate: null, destination: null, active: false });
const editGroup = ref(structuredClone(formNewGroup.value));
const errors = ref([]);
const successes = ref([]);
const groupForm = ref(null);

const formUpdate = e => {
  editGroup.value = e;
};

const closeAlert = () => {
  errors.value = [];
  successes.value = [];
};

const validateState = () => {
  const formValidation = groupForm.value.form.validate();

  if (!formValidation) {
    successes.value = [];
    errors.value = [getLabel('check_fields')];

    return false;
  }

  return true;
};

const createGroup = async () => {
  if (validateState()) {
    store.state.loading = true;

    const room = {
      name: editGroup.value.name,
      active: true,
      metadata: JSON.stringify({
        departure_date: editGroup.value.departureDate,
        center_destination_uuid: editGroup.value.destination,
        workspace: store.getters.getActiveBrand
      })
    };

    const response = await requests.postRoom(room);
    console.log('response :', response);
    setTimeout(() => {
      store.state.loading = false;
      router.push({ name: 'Home' });
    }, 2000);
    return;
  }
  console.log('Not Validated');
};

const resetForm = () => {
  closeAlert();
  groupForm.value.formReset();
};

const goBack = () => {
  router.back();
};
</script>

<style></style>
