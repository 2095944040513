<template>
  <div>
    <v-card v-if="notFound" class="ma-6" color="warning" rounded="lg" outlined elevation="4">
      <v-card-text class="white--text text-h6 d-inline-flex justify-space-between">
        <div>
          Groupe introuvable!
        </div>
        <v-btn @click="goBack">Retourner</v-btn>
      </v-card-text>
    </v-card>
    <v-card v-else-if="changingWorkspace" class="ma-6" color="warning" rounded="lg" outlined elevation="4">
      <v-card-text class="white--text text-h6 d-inline-flex justify-space-between">
        <div>
          Vous déménagez vers un autre workspace, souhaitez-vous continuer?
        </div>
        <div>
          <v-btn class="mr-4" @click="changeWorkspace">Continuer</v-btn>
          <v-btn @click="goBack">Retourner</v-btn>
        </div>
      </v-card-text>
    </v-card>
    <v-container v-else-if="report">
      <div class="d-flex align-center pb-3">
        <v-btn text icon color="black" @click="goBack">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <div class="text-h4 font-weight-medium ml-1">
          {{ getLabel('report') }}
        </div>
      </div>
      <v-expansion-panels v-model="panel" multiple>
        <v-expansion-panel active-class="pa-0">
          <v-expansion-panel-header color="primary white--text">
            <span class="text-h6">{{ getLabel('report_origin') }}</span>
            <template #actions>
              <v-icon class="arrow-icon m-0" color="white">
                $expand
              </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-container>
              <v-row>
                <v-col>
                  <p class="subtitle-2">{{ getLabel('report_user') }}</p>
                  <span>{{ report.reporter.firstName }} </span>
                  <span class="text-uppercase">{{ report.reporter.lastName }}</span>
                </v-col>
                <v-col>
                  <p class="subtitle-2">{{ getLabel('group_url') }}</p>
                  <a :href="report.link" target="_blank">{{ report.link }}</a>
                </v-col>
              </v-row>
              <v-divider class="mb-2" />
              <v-row>
                <v-col>
                  <p class="subtitle-2">{{ getLabel('reported_user') }}</p>
                  <span>{{ report.reported.firstName }} </span>
                  <span class="text-uppercase">{{ report.reported.lastName }}</span>
                </v-col>
                <v-col>
                  <p class="subtitle-2">{{ getLabel('group_name') }}</p>
                  <p>{{ report.groupName }}, {{ report.departureDate }}</p>
                </v-col>
              </v-row>
              <v-divider class="mb-2" />
              <v-row>
                <v-col>
                  <p class="subtitle-2">{{ getLabel('report_date') }}</p>
                  <p>{{ report.date }}</p>
                </v-col>
                <v-col>
                  <p class="subtitle-2">{{ getLabel('report_id') }}</p>
                  <p>{{ report.id }}</p>
                </v-col>
              </v-row>
              <v-divider class="mb-2" />
              <v-row>
                <v-col>
                  <p class="subtitle-2">{{ getLabel('report_type') }}</p>
                  <p>{{ report.typeLabel }}</p>
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header color="primary white--text">
            <span class="text-h6">{{ getLabel('participants_information') }}</span>
            <template #actions>
              <v-icon class="arrow-icon m-0" color="white">
                $expand
              </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-container>
              <div class="pa-4 d-flex flex-column">
                <span>{{ report.date }} à {{ report.time }} par {{ report.reporter.firstName }}</span>
                <br>
                <div>{{ report.content }}</div>
              </div>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-container>
  </div>
</template>

<script setup>
import { ref, onMounted, defineProps } from 'vue';
import { useRouter } from 'vue2-helpers/vue-router';
import { format } from 'date-fns';
import getLabel from '@/utils/labels';
import { requests } from '@/mixins/requests';
import store from '@/store';

const typeLabels = require('@/configs/reportsTypeLabels.json');

const props = defineProps({ id: { type: String, default: 'id' } });

const router = useRouter();

const report = ref(null);
const notFound = ref(false);
const changingWorkspace = ref(false);
const panel = ref([0, 1]);

const changeWorkspace = () => {
  store.commit('setActiveBrand', report.value.workspace);
  changingWorkspace.value = false;
};

const prepareReport = async newReport => {
  if (newReport) {
    const userResponse = await requests.getUser(newReport.message.userId);
    report.value = {
      id: newReport.id,
      content: newReport.content,
      date: format(new Date(newReport.createdAt), 'yyyy-MM-dd'),
      time: format(new Date(newReport.createdAt), 'HH:mm'),
      groupName: newReport.room ? newReport.room.name : '----------------',
      link: newReport.room ? `${store.getters.getBrandUrl}/${newReport.room.id}` : '----------------',
      reporter: {
        firstName: newReport.user ? newReport.user.firstname : '',
        lastName: newReport.user ? newReport.user.lastname : ''
      },
      reported: {
        firstName: userResponse.data.firstname,
        lastName: userResponse.data.lastname
      },
      type: newReport.type,
      typeLabel: typeLabels[newReport.type] || newReport.type,
      workspace: JSON.parse(newReport.metadata).workspace
    };
  } else {
    report.value = null;
  }
};

onMounted(async () => {
  if (!props.id) {
    router.push({ name: 'ReportsDashboard' });
    return;
  }
  const resp = (await requests.getReport(props.id));

  console.log('REPORT RESPONSE: ', resp);

  if (!resp.data) {
    notFound.value = true;
    return;
  }
  await prepareReport(resp.data);

  if (report.value.workspace !== store.getters.getActiveBrand) {
    changingWorkspace.value = true;
  }
});

const goBack = () => {
  router.back();
};

</script>
