import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import Cookies from 'js-cookie';

Vue.use(Vuex);

const { VUE_APP_ENVIROMENT } = process.env;

const store = new Vuex.Store({
  plugins: [createPersistedState({
    storage: window.sessionStorage,
    paths: ['contextInfo', 'query', 'filters', 'activeBrand', 'activeDestination', 'activeContext', 'contextFacets', 'destinationFacets']
  })],
  state: {
    token: Cookies.get('CF_Authorization'),
    user: null,
    userPlatforms: [], // write workspaces
    readWorkspaces: [],
    userRoles: [],
    contextInfo: {
      partner: 1,
      sales_type: 1,
      allotement: 1,
      fata_id: 0,
      rese_id: 0,
      context_action: 'formcreate'
    },
    canWrite: false,
    query: '*',
    loader: false,
    loading: false,
    activeBrand: 'crm',
    activeDestination: null,
    activeContext: null,
    resetSearchInsideEachFacet: false,
    contextFacets: null,
    destinationFacets: null
  },
  getters: {
    getToken (state) {
      return state.token;
    },
    getActiveBrand (state) {
      return state.activeBrand;
    },
    getActiveDestination (state) {
      return state.activeDestination;
    },
    getActiveContext (state) {
      return state.activeContext;
    },
    getContextFacets (state) {
      return state.contextFacets;
    },
    getDestinationFacets (state) {
      return state.destinationFacets;
    },
    getBrandUrl (state) {
      return VUE_APP_ENVIROMENT === 'prod' ? `https://hello.${state.activeBrand === 'crm' ? 'ucpa.com' : 'labalaguere.com'}` : `https://recette.hello.${state.activeBrand === 'crm' ? 'speedboat' : 'labalaguere'}.ucpa.com`;
    },
    allowAction: state => contentRoles => {
      const userRoles = state.user.roles || null;
      if (!userRoles) return false;

      if (userRoles.includes('admin')) return true;

      let isAllowed = false;
      contentRoles.map(contentRole => {
        if (userRoles.includes(contentRole)) isAllowed = true;
      });

      return isAllowed;
    },
    allowField: state => contentHideField => {
      let isAllowed = true;

      const filterFounded = state.filters.find(filter => filter.field === contentHideField.fieldName);

      if (filterFounded && contentHideField.values.includes(filterFounded.value)) {
        isAllowed = false;
      }

      return isAllowed;
    }
  },
  actions: {},
  mutations: {
    setUserPlatforms (state, data) {
      Vue.set(state, 'userPlatforms', data);
    },
    setReadWorkspaces (state, data) {
      Vue.set(state, 'readWorkspaces', data);
    },
    setUserRoles (state, data) {
      Vue.set(state, 'userRoles', data);
    },
    setActiveBrand (state, data) {
      Vue.set(state, 'activeBrand', data);
    },
    setActiveDestination (state, data) {
      Vue.set(state, 'activeDestination', data);
    },
    setActiveContext (state, data) {
      Vue.set(state, 'activeContext', data);
    },
    setContextFacets (state, data) {
      Vue.set(state, 'contextFacets', data);
    },
    setDestinationFacets (state, data) {
      Vue.set(state, 'destinationFacets', data);
    },
    setUser (state, data) {
      Vue.set(state, 'user', data);
    },
    setCanWrite (state, data) {
      Vue.set(state, 'canWrite', data);
    },
    setContextInfo (state, data) {
      Vue.set(state, 'contextInfo', data);
    },
    setQuery (state, data) {
      Vue.set(state, 'query', data);
    },
    setLoader (state, data) {
      Vue.set(state, 'loader', data);
    },
    setResetSearchInsideEachFacet (state, data) {
      Vue.set(state, 'resetSearchInsideEachFacet', data);
    }
  }
});

export default store;
