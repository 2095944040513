const getBirthDate = age => {
  const today = new Date();
  today.setFullYear(today.getFullYear() - age);
  return today.toISOString();
};

const getFilterValue = (value, facet) => {
  if (facet.dataType === 'age') return getBirthDate(value);
  return value;
};

const transformFilters = (facet, filterValue) => {
  let filter = null;
  if (facet.type === 'multiValue') {
    if (filterValue && filterValue.length) {
      filter = {
        or: filterValue.map(f => {
          const conditionObject = { field: facet.filterField };
          conditionObject.condition = {};
          conditionObject.condition[facet.filterCondition || 'eq'] = f;
          return conditionObject;
        })
      };
    }
  } else if (facet.type === 'range') {
    if (filterValue && filterValue.length) {
      filter = { field: facet.filterField, condition: { lte: getFilterValue(filterValue[0], facet), gte: getFilterValue(filterValue[1], facet) } };
    }
  } else if (filterValue) {
    filter = { field: facet.filterField };
    filter[facet.conditionType || 'condition'] = {};
    filter[facet.conditionType || 'condition'][facet.filterCondition || 'eq'] = filterValue;
  }
  return filter;
};

export default transformFilters;
